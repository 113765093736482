import Vue from 'vue'
import {
  Button,
  Form,
  Field,
  Popup,
  Picker,
  NavBar,
  CellGroup,
  Cell,
  Overlay,
  Loading,
  RadioGroup, 
  Radio,
  Dialog
} from 'vant'

Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(NavBar)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Overlay)
Vue.use(Loading)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Dialog)
