/* eslint-disable */
function w(a) {
  if (null == a || void 0 == a || '' == a) return 'NA'
  if (null == a || void 0 == a || '' == a) var b = ''
  else {
    b = []
    for (var c = 0; c < 8 * a.length; c += 8)
      b[c >> 5] |= (a.charCodeAt(c / 8) & 255) << c % 32
  }
  a = 8 * a.length
  b[a >> 5] |= 128 << a % 32
  b[(((a + 64) >>> 9) << 4) + 14] = a
  a = 1732584193
  c = -271733879
  for (var h = -1732584194, k = 271733878, r = 0; r < b.length; r += 16) {
    var A = a,
      D = c,
      F = h,
      C = k
    a = v(a, c, h, k, b[r + 0], 7, -680876936)
    k = v(k, a, c, h, b[r + 1], 12, -389564586)
    h = v(h, k, a, c, b[r + 2], 17, 606105819)
    c = v(c, h, k, a, b[r + 3], 22, -1044525330)
    a = v(a, c, h, k, b[r + 4], 7, -176418897)
    k = v(k, a, c, h, b[r + 5], 12, 1200080426)
    h = v(h, k, a, c, b[r + 6], 17, -1473231341)
    c = v(c, h, k, a, b[r + 7], 22, -45705983)
    a = v(a, c, h, k, b[r + 8], 7, 1770035416)
    k = v(k, a, c, h, b[r + 9], 12, -1958414417)
    h = v(h, k, a, c, b[r + 10], 17, -42063)
    c = v(c, h, k, a, b[r + 11], 22, -1990404162)
    a = v(a, c, h, k, b[r + 12], 7, 1804603682)
    k = v(k, a, c, h, b[r + 13], 12, -40341101)
    h = v(h, k, a, c, b[r + 14], 17, -1502002290)
    c = v(c, h, k, a, b[r + 15], 22, 1236535329)
    a = y(a, c, h, k, b[r + 1], 5, -165796510)
    k = y(k, a, c, h, b[r + 6], 9, -1069501632)
    h = y(h, k, a, c, b[r + 11], 14, 643717713)
    c = y(c, h, k, a, b[r + 0], 20, -373897302)
    a = y(a, c, h, k, b[r + 5], 5, -701558691)
    k = y(k, a, c, h, b[r + 10], 9, 38016083)
    h = y(h, k, a, c, b[r + 15], 14, -660478335)
    c = y(c, h, k, a, b[r + 4], 20, -405537848)
    a = y(a, c, h, k, b[r + 9], 5, 568446438)
    k = y(k, a, c, h, b[r + 14], 9, -1019803690)
    h = y(h, k, a, c, b[r + 3], 14, -187363961)
    c = y(c, h, k, a, b[r + 8], 20, 1163531501)
    a = y(a, c, h, k, b[r + 13], 5, -1444681467)
    k = y(k, a, c, h, b[r + 2], 9, -51403784)
    h = y(h, k, a, c, b[r + 7], 14, 1735328473)
    c = y(c, h, k, a, b[r + 12], 20, -1926607734)
    a = u(c ^ h ^ k, a, c, b[r + 5], 4, -378558)
    k = u(a ^ c ^ h, k, a, b[r + 8], 11, -2022574463)
    h = u(k ^ a ^ c, h, k, b[r + 11], 16, 1839030562)
    c = u(h ^ k ^ a, c, h, b[r + 14], 23, -35309556)
    a = u(c ^ h ^ k, a, c, b[r + 1], 4, -1530992060)
    k = u(a ^ c ^ h, k, a, b[r + 4], 11, 1272893353)
    h = u(k ^ a ^ c, h, k, b[r + 7], 16, -155497632)
    c = u(h ^ k ^ a, c, h, b[r + 10], 23, -1094730640)
    a = u(c ^ h ^ k, a, c, b[r + 13], 4, 681279174)
    k = u(a ^ c ^ h, k, a, b[r + 0], 11, -358537222)
    h = u(k ^ a ^ c, h, k, b[r + 3], 16, -722521979)
    c = u(h ^ k ^ a, c, h, b[r + 6], 23, 76029189)
    a = u(c ^ h ^ k, a, c, b[r + 9], 4, -640364487)
    k = u(a ^ c ^ h, k, a, b[r + 12], 11, -421815835)
    h = u(k ^ a ^ c, h, k, b[r + 15], 16, 530742520)
    c = u(h ^ k ^ a, c, h, b[r + 2], 23, -995338651)
    a = x(a, c, h, k, b[r + 0], 6, -198630844)
    k = x(k, a, c, h, b[r + 7], 10, 1126891415)
    h = x(h, k, a, c, b[r + 14], 15, -1416354905)
    c = x(c, h, k, a, b[r + 5], 21, -57434055)
    a = x(a, c, h, k, b[r + 12], 6, 1700485571)
    k = x(k, a, c, h, b[r + 3], 10, -1894986606)
    h = x(h, k, a, c, b[r + 10], 15, -1051523)
    c = x(c, h, k, a, b[r + 1], 21, -2054922799)
    a = x(a, c, h, k, b[r + 8], 6, 1873313359)
    k = x(k, a, c, h, b[r + 15], 10, -30611744)
    h = x(h, k, a, c, b[r + 6], 15, -1560198380)
    c = x(c, h, k, a, b[r + 13], 21, 1309151649)
    a = x(a, c, h, k, b[r + 4], 6, -145523070)
    k = x(k, a, c, h, b[r + 11], 10, -1120210379)
    h = x(h, k, a, c, b[r + 2], 15, 718787259)
    c = x(c, h, k, a, b[r + 9], 21, -343485551)
    a = B(a, A)
    c = B(c, D)
    h = B(h, F)
    k = B(k, C)
  }
  b = [a, c, h, k]
  a = ''
  for (c = 0; c < 4 * b.length; c++)
    a +=
      '0123456789abcdef'.charAt((b[c >> 2] >> ((c % 4) * 8 + 4)) & 15) +
      '0123456789abcdef'.charAt((b[c >> 2] >> ((c % 4) * 8)) & 15)
  return a
}

function u(a, b, c, h, k, r) {
  a = B(B(b, a), B(h, r))
  return B((a << k) | (a >>> (32 - k)), c)
}

function v(a, b, c, h, k, r, A) {
  return u((b & c) | (~b & h), a, b, k, r, A)
}

function y(a, b, c, h, k, r, A) {
  return u((b & h) | (c & ~h), a, b, k, r, A)
}

function x(a, b, c, h, k, r, A) {
  return u(c ^ (b | ~h), a, b, k, r, A)
}

function B(a, b) {
  var c = (a & 65535) + (b & 65535)
  return (((a >> 16) + (b >> 16) + (c >> 16)) << 16) | (c & 65535)
}

var l = '',
  f = navigator.userAgent.toLowerCase()
f.indexOf('jdapp') && (f = f.substring(0, 90))
var q = navigator.language,
  t = 'pc',
  e = f
if (
  -1 != e.indexOf('ipad') ||
  -1 != e.indexOf('iphone os') ||
  -1 != e.indexOf('midp') ||
  -1 != e.indexOf('rv:1.2.3.4') ||
  -1 != e.indexOf('ucweb') ||
  -1 != e.indexOf('android') ||
  -1 != e.indexOf('windows ce') ||
  -1 != e.indexOf('windows mobile')
)
  t = 'mobile'
__global_origin = t
var z = 'NA',
  g = 'NA'
try {
  ;-1 != e.indexOf('win') &&
    -1 != e.indexOf('95') &&
    ((z = 'windows'), (g = '95')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('98') &&
      ((z = 'windows'), (g = '98')),
    -1 != e.indexOf('win 9x') &&
      -1 != e.indexOf('4.90') &&
      ((z = 'windows'), (g = 'me')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('nt 5.0') &&
      ((z = 'windows'), (g = '2000')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('nt') &&
      ((z = 'windows'), (g = 'NT')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('nt 5.1') &&
      ((z = 'windows'), (g = 'xp')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('32') &&
      ((z = 'windows'), (g = '32')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('nt 5.1') &&
      ((z = 'windows'), (g = '7')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('6.0') &&
      ((z = 'windows'), (g = '8')),
    -1 == e.indexOf('win') ||
      (-1 == e.indexOf('nt 6.0') && -1 == e.indexOf('nt 6.1')) ||
      ((z = 'windows'), (g = '9')),
    -1 != e.indexOf('win') &&
      -1 != e.indexOf('nt 6.2') &&
      ((z = 'windows'), (g = '10')),
    -1 != e.indexOf('linux') && (z = 'linux'),
    -1 != e.indexOf('unix') && (z = 'unix'),
    -1 != e.indexOf('sun') && -1 != e.indexOf('os') && (z = 'sun os'),
    -1 != e.indexOf('ibm') && -1 != e.indexOf('os') && (z = 'ibm os/2'),
    -1 != e.indexOf('mac') && -1 != e.indexOf('pc') && (z = 'mac'),
    -1 != e.indexOf('aix') && (z = 'aix'),
    -1 != e.indexOf('powerpc') && (z = 'powerPC'),
    -1 != e.indexOf('hpux') && (z = 'hpux'),
    -1 != e.indexOf('netbsd') && (z = 'NetBSD'),
    -1 != e.indexOf('bsd') && (z = 'BSD'),
    -1 != e.indexOf('osf1') && (z = 'OSF1'),
    -1 != e.indexOf('irix') && ((z = 'IRIX'), (g = '')),
    -1 != e.indexOf('freebsd') && (z = 'FreeBSD'),
    -1 != e.indexOf('symbianos') &&
      ((z = 'SymbianOS'), (g = e.substring(e.indexOf('SymbianOS/') + 10, 3))),
    (__global_os = z),
    (__global_osVersion = g)
} catch (a) {}
_fingerprint_step = 3
var n = 'NA',
  m = 'NA'
try {
  ;-1 != e.indexOf('msie') &&
    ((n = 'ie'),
    (m = e.substring(e.indexOf('msie ') + 5)),
    m.indexOf(';') && (m = m.substring(0, m.indexOf(';'))))
  ;-1 != e.indexOf('firefox') &&
    ((n = 'Firefox'), (m = e.substring(e.indexOf('firefox/') + 8)))
  ;-1 != e.indexOf('opera') &&
    ((n = 'Opera'), (m = e.substring(e.indexOf('opera/') + 6, 4)))
  ;-1 != e.indexOf('safari') &&
    ((n = 'safari'), (m = e.substring(e.indexOf('safari/') + 7)))
  ;-1 != e.indexOf('chrome') &&
    ((n = 'chrome'),
    (m = e.substring(e.indexOf('chrome/') + 7)),
    m.indexOf(' ') && (m = m.substring(0, m.indexOf(' '))))
  ;-1 != e.indexOf('navigator') &&
    ((n = 'navigator'), (m = e.substring(e.indexOf('navigator/') + 10)))
  ;-1 != e.indexOf('applewebkit') &&
    ((n = 'applewebkit_chrome'),
    (m = e.substring(e.indexOf('applewebkit/') + 12)),
    m.indexOf(' ') && (m = m.substring(0, m.indexOf(' '))))
  ;-1 != e.indexOf('sogoumobilebrowser') &&
    (n = '\u641c\u72d7\u624b\u673a\u6d4f\u89c8\u5668')
  if (-1 != e.indexOf('ucbrowser') || -1 != e.indexOf('ucweb'))
    n = 'UC\u6d4f\u89c8\u5668'
  if (-1 != e.indexOf('qqbrowser') || -1 != e.indexOf('tencenttraveler'))
    n = 'QQ\u6d4f\u89c8\u5668'
  ;-1 != e.indexOf('metasr') && (n = '\u641c\u72d7\u6d4f\u89c8\u5668')
  ;-1 != e.indexOf('360se') && (n = '360\u6d4f\u89c8\u5668')
  ;-1 != e.indexOf('the world') &&
    (n = '\u4e16\u754c\u4e4b\u7a97\u6d4f\u89c8\u5668')
  ;-1 != e.indexOf('maxthon') && (n = '\u9068\u6e38\u6d4f\u89c8\u5668')
} catch (a) {}
var xingsha = {
  options: {},
  extend: function (a, b) {
    if (null == a) return b
    for (var c in a) null != a[c] && b[c] !== a[c] && (b[c] = a[c])
    return b
  },
  getData: function () {
    return l
  },
  get: function (a) {
    var b = 1 * m,
      c = []
    'ie' == n && 7 <= b
      ? (c.push(f),
        c.push(q),
        (l = l + ",'userAgent':'" + w(f) + "','language':'" + q + "'"),
        this.browserRedirect(f))
      : ((c = this.userAgentKey(c)), (c = this.languageKey(c)))
    c.push(n)
    c.push(m)
    c.push(z)
    c.push(g)
    l =
      l +
      ",'os':'" +
      z +
      "','osVersion':'" +
      g +
      "','browser':'" +
      n +
      "','browserVersion':'" +
      m +
      "'"
    c = this.colorDepthKey(c)
    c = this.screenResolutionKey(c)
    c = this.timezoneOffsetKey(c)
    c = this.sessionStorageKey(c)
    c = this.localStorageKey(c)
    c = this.indexedDbKey(c)
    c = this.addBehaviorKey(c)
    c = this.openDatabaseKey(c)
    c = this.cpuClassKey(c)
    c = this.platformKey(c)
    c = this.hardwareConcurrencyKey(c)
    c = this.doNotTrackKey(c)
    c = this.pluginsKey(c)
    c = this.canvasKey(c)
    c = this.webglKey(c)
    b = this.x64hash128(c.join('~~~'), 31)
    return b
  },
  userAgentKey: function (a) {
    a.push(navigator.userAgent),
      (l = l + ",'userAgent':'" + w(navigator.userAgent) + "'"),
      this.browserRedirect(navigator.userAgent)
    return a
  },
  replaceAll: function (a, b, c) {
    for (; 0 <= a.indexOf(b); ) a = a.replace(b, c)
    return a
  },
  browserRedirect: function (a) {
    var b = a.toLowerCase()
    a = 'ipad' == b.match(/ipad/i)
    var c = 'iphone os' == b.match(/iphone os/i),
      h = 'midp' == b.match(/midp/i),
      k = 'rv:1.2.3.4' == b.match(/rv:1.2.3.4/i),
      r = 'ucweb' == b.match(/ucweb/i),
      A = 'android' == b.match(/android/i),
      D = 'windows ce' == b.match(/windows ce/i)
    b = 'windows mobile' == b.match(/windows mobile/i)
    l =
      a || c || h || k || r || A || D || b
        ? l + ",'origin':'mobile'"
        : l + ",'origin':'pc'"
  },
  languageKey: function (a) {
    this.options.excludeLanguage ||
      (a.push(navigator.language),
      (l =
        l +
        ",'language':'" +
        this.replaceAll(navigator.language, ' ', '_') +
        "'"))
    return a
  },
  colorDepthKey: function (a) {
    this.options.excludeColorDepth ||
      (a.push(screen.colorDepth),
      (l = l + ",'colorDepth':'" + screen.colorDepth + "'"))
    return a
  },
  screenResolutionKey: function (a) {
    if (!this.options.excludeScreenResolution) {
      var b = this.getScreenResolution()
      'undefined' !== typeof b &&
        (a.push(b.join('x')),
        (l = l + ",'screenResolution':'" + b.join('x') + "'"))
    }
    return a
  },
  getScreenResolution: function () {
    return this.options.detectScreenOrientation
      ? screen.height > screen.width
        ? [screen.height, screen.width]
        : [screen.width, screen.height]
      : [screen.height, screen.width]
  },
  timezoneOffsetKey: function (a) {
    this.options.excludeTimezoneOffset ||
      (a.push(new Date().getTimezoneOffset()),
      (l =
        l + ",'timezoneOffset':'" + new Date().getTimezoneOffset() / 60 + "'"))
    return a
  },
  sessionStorageKey: function (a) {
    !this.options.excludeSessionStorage &&
      this.hasSessionStorage() &&
      (a.push('sessionStorageKey'), (l += ",'sessionStorage':true"))
    return a
  },
  localStorageKey: function (a) {
    !this.options.excludeSessionStorage &&
      this.hasLocalStorage() &&
      (a.push('localStorageKey'), (l += ",'localStorage':true"))
    return a
  },
  indexedDbKey: function (a) {
    !this.options.excludeIndexedDB &&
      this.hasIndexedDB() &&
      (a.push('indexedDbKey'), (l += ",'indexedDb':true"))
    return a
  },
  addBehaviorKey: function (a) {
    document.body &&
    !this.options.excludeAddBehavior &&
    document.body.addBehavior
      ? (a.push('addBehaviorKey'), (l += ",'addBehavior':true"))
      : (l += ",'addBehavior':false")
    return a
  },
  openDatabaseKey: function (a) {
    !this.options.excludeOpenDatabase && window.openDatabase
      ? (a.push('openDatabase'), (l += ",'openDatabase':true"))
      : (l += ",'openDatabase':false")
    return a
  },
  cpuClassKey: function (a) {
    this.options.excludeCpuClass ||
      (a.push(this.getNavigatorCpuClass()),
      (l = l + ",'cpu':'" + this.getNavigatorCpuClass() + "'"))
    return a
  },
  platformKey: function (a) {
    this.options.excludePlatform ||
      (a.push(this.getNavigatorPlatform()),
      (l = l + ",'platform':'" + this.getNavigatorPlatform() + "'"))
    return a
  },
  hardwareConcurrencyKey: function (a) {
    var b = this.getHardwareConcurrency()
    a.push(b)
    l = l + ",'ccn':'" + b + "'"
    return a
  },
  doNotTrackKey: function (a) {
    this.options.excludeDoNotTrack ||
      (a.push(this.getDoNotTrack()),
      (l = l + ",'track':'" + this.getDoNotTrack() + "'"))
    return a
  },
  canvasKey: function (a) {
    if (!this.options.excludeCanvas && this.isCanvasSupported()) {
      var b = this.getCanvasFp()
      a.push(b)
      _jdfp_canvas_md5 = w(b)
      l = l + ",'canvas':'" + _jdfp_canvas_md5 + "'"
    }
    return a
  },
  webglKey: function (a) {
    if (!this.options.excludeWebGL && this.isCanvasSupported()) {
      var b = this.getWebglFp()
      _jdfp_webgl_md5 = w(b)
      a.push(b)
      l = l + ",'webglFp':'" + _jdfp_webgl_md5 + "'"
    }
    return a
  },
  pluginsKey: function (a) {
    this.isIE()
      ? (a.push(this.getIEPluginsString()),
        (l = l + ",'plugins':'" + w(this.getIEPluginsString()) + "'"))
      : (a.push(this.getRegularPluginsString()),
        (l = l + ",'plugins':'" + w(this.getRegularPluginsString()) + "'"))
    return a
  },
  getRegularPluginsString: function () {
    return this.map(
      navigator.plugins,
      function (a) {
        var b = this.map(a, function (c) {
          return [c.type, c.suffixes].join('~')
        }).join(',')
        return [a.name, a.description, b].join('::')
      },
      this
    ).join(';')
  },
  getIEPluginsString: function () {
    return window.ActiveXObject
      ? this.map(
          'AcroPDF.PDF;Adodb.Stream;AgControl.AgControl;DevalVRXCtrl.DevalVRXCtrl.1;MacromediaFlashPaper.MacromediaFlashPaper;Msxml2.DOMDocument;Msxml2.XMLHTTP;PDF.PdfCtrl;QuickTime.QuickTime;QuickTimeCheckObject.QuickTimeCheck.1;RealPlayer;RealPlayer.RealPlayer(tm) ActiveX Control (32-bit);RealVideo.RealVideo(tm) ActiveX Control (32-bit);Scripting.Dictionary;SWCtl.SWCtl;Shell.UIHelper;ShockwaveFlash.ShockwaveFlash;Skype.Detection;TDCCtl.TDCCtl;WMPlayer.OCX;rmocx.RealPlayer G2 Control;rmocx.RealPlayer G2 Control.1'.split(
            ';'
          ),
          function (a) {
            try {
              return new ActiveXObject(a), a
            } catch (b) {
              return null
            }
          }
        ).join(';')
      : ''
  },
  hasSessionStorage: function () {
    try {
      return !!window.sessionStorage
    } catch (a) {
      return !0
    }
  },
  hasLocalStorage: function () {
    try {
      return !!window.localStorage
    } catch (a) {
      return !0
    }
  },
  hasIndexedDB: function () {
    return !!window.indexedDB
  },
  getNavigatorCpuClass: function () {
    return navigator.cpuClass ? navigator.cpuClass : 'NA'
  },
  getNavigatorPlatform: function () {
    return navigator.platform ? navigator.platform : 'NA'
  },
  getHardwareConcurrency: function () {
    return navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 'NA'
  },
  getDoNotTrack: function () {
    return navigator.doNotTrack ? navigator.doNotTrack : 'NA'
  },
  getCanvasFp: function () {
    var a = navigator.userAgent.toLowerCase()
    if (
      (0 < a.indexOf('jdjr-app') || 0 <= a.indexOf('jdapp')) &&
      (0 < a.indexOf('iphone') || 0 < a.indexOf('ipad'))
    )
      return null
    a = document.createElement('canvas')
    var b = a.getContext('2d')
    b.fillStyle = 'red'
    b.fillRect(30, 10, 200, 100)
    b.strokeStyle = '#1a3bc1'
    b.lineWidth = 6
    b.lineCap = 'round'
    b.arc(50, 50, 20, 0, Math.PI, !1)
    b.stroke()
    b.fillStyle = '#42e1a2'
    b.font = "15.4px 'Arial'"
    b.textBaseline = 'alphabetic'
    b.fillText('PR flacks quiz gym: TV DJ box when? \u2620', 15, 60)
    b.shadowOffsetX = 1
    b.shadowOffsetY = 2
    b.shadowColor = 'white'
    b.fillStyle = 'rgba(0, 0, 200, 0.5)'
    b.font = "60px 'Not a real font'"
    b.fillText('No\u9a97', 40, 80)
    return a.toDataURL()
  },
  getWebglFp: function () {
    var a = navigator.userAgent
    a = a.toLowerCase()
    if (
      (0 < a.indexOf('jdjr-app') || 0 <= a.indexOf('jdapp')) &&
      (0 < a.indexOf('iphone') || 0 < a.indexOf('ipad'))
    )
      return null
    a = function (F) {
      b.clearColor(0, 0, 0, 1)
      b.enable(b.DEPTH_TEST)
      b.depthFunc(b.LEQUAL)
      b.clear(b.COLOR_BUFFER_BIT | b.DEPTH_BUFFER_BIT)
      return '[' + F[0] + ', ' + F[1] + ']'
    }
    var b = this.getWebglCanvas()
    if (!b) return null
    var c = [],
      h = b.createBuffer()
    b.bindBuffer(b.ARRAY_BUFFER, h)
    var k = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0])
    b.bufferData(b.ARRAY_BUFFER, k, b.STATIC_DRAW)
    h.itemSize = 3
    h.numItems = 3
    k = b.createProgram()
    var r = b.createShader(b.VERTEX_SHADER)
    b.shaderSource(
      r,
      'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}'
    )
    b.compileShader(r)
    var A = b.createShader(b.FRAGMENT_SHADER)
    b.shaderSource(
      A,
      'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}'
    )
    b.compileShader(A)
    b.attachShader(k, r)
    b.attachShader(k, A)
    b.linkProgram(k)
    b.useProgram(k)
    k.vertexPosAttrib = b.getAttribLocation(k, 'attrVertex')
    k.offsetUniform = b.getUniformLocation(k, 'uniformOffset')
    b.enableVertexAttribArray(k.vertexPosArray)
    b.vertexAttribPointer(k.vertexPosAttrib, h.itemSize, b.FLOAT, !1, 0, 0)
    b.uniform2f(k.offsetUniform, 1, 1)
    b.drawArrays(b.TRIANGLE_STRIP, 0, h.numItems)
    null != b.canvas && c.push(b.canvas.toDataURL())
    c.push('extensions:' + b.getSupportedExtensions().join(';'))
    c.push('extensions:' + b.getSupportedExtensions().join(';'))
    c.push('w1' + a(b.getParameter(b.ALIASED_LINE_WIDTH_RANGE)))
    c.push('w2' + a(b.getParameter(b.ALIASED_POINT_SIZE_RANGE)))
    c.push('w3' + b.getParameter(b.ALPHA_BITS))
    c.push('w4' + (b.getContextAttributes().antialias ? 'yes' : 'no'))
    c.push('w5' + b.getParameter(b.BLUE_BITS))
    c.push('w6' + b.getParameter(b.DEPTH_BITS))
    c.push('w7' + b.getParameter(b.GREEN_BITS))
    c.push(
      'w8' +
        (function (F) {
          var C,
            G =
              F.getExtension('EXT_texture_filter_anisotropic') ||
              F.getExtension('WEBKIT_EXT_texture_filter_anisotropic') ||
              F.getExtension('MOZ_EXT_texture_filter_anisotropic')
          return G
            ? ((C = F.getParameter(G.MAX_TEXTURE_MAX_ANISOTROPY_EXT)),
              0 === C && (C = 2),
              C)
            : null
        })(b)
    )
    c.push('w9' + b.getParameter(b.MAX_COMBINED_TEXTURE_IMAGE_UNITS))
    c.push('w10' + b.getParameter(b.MAX_CUBE_MAP_TEXTURE_SIZE))
    c.push('w11' + b.getParameter(b.MAX_FRAGMENT_UNIFORM_VECTORS))
    c.push('w12' + b.getParameter(b.MAX_RENDERBUFFER_SIZE))
    c.push('w13' + b.getParameter(b.MAX_TEXTURE_IMAGE_UNITS))
    c.push('w14' + b.getParameter(b.MAX_TEXTURE_SIZE))
    c.push('w15' + b.getParameter(b.MAX_VARYING_VECTORS))
    c.push('w16' + b.getParameter(b.MAX_VERTEX_ATTRIBS))
    c.push('w17' + b.getParameter(b.MAX_VERTEX_TEXTURE_IMAGE_UNITS))
    c.push('w18' + b.getParameter(b.MAX_VERTEX_UNIFORM_VECTORS))
    c.push('w19' + a(b.getParameter(b.MAX_VIEWPORT_DIMS)))
    c.push('w20' + b.getParameter(b.RED_BITS))
    c.push('w21' + b.getParameter(b.RENDERER))
    c.push('w22' + b.getParameter(b.SHADING_LANGUAGE_VERSION))
    c.push('w23' + b.getParameter(b.STENCIL_BITS))
    c.push('w24' + b.getParameter(b.VENDOR))
    c.push('w25' + b.getParameter(b.VERSION))
    try {
      var D = b.getExtension('WEBGL_debug_renderer_info')
      D &&
        (c.push('wuv:' + b.getParameter(D.UNMASKED_VENDOR_WEBGL)),
        c.push('wur:' + b.getParameter(D.UNMASKED_RENDERER_WEBGL)))
    } catch (F) {}
    return c.join('\u00a7')
  },
  isCanvasSupported: function () {
    var a = document.createElement('canvas')
    return !(!a.getContext || !a.getContext('2d'))
  },
  isIE: function () {
    return 'Microsoft Internet Explorer' === navigator.appName ||
      ('Netscape' === navigator.appName && /Trident/.test(navigator.userAgent))
      ? !0
      : !1
  },
  getWebglCanvas: function () {
    var a = document.createElement('canvas'),
      b = null
    try {
      var c = navigator.userAgent
      c = c.toLowerCase()
      ;((0 < c.indexOf('jdjr-app') || 0 <= c.indexOf('jdapp')) &&
        (0 < c.indexOf('iphone') || 0 < c.indexOf('ipad'))) ||
        (b = a.getContext('webgl') || a.getContext('experimental-webgl'))
    } catch (h) {}
    b || (b = null)
    return b
  },
  each: function (a, b, c) {
    if (null !== a)
      if (this.nativeForEach && a.forEach === this.nativeForEach)
        a.forEach(b, c)
      else if (a.length === +a.length)
        for (
          var h = 0, k = a.length;
          h < k && b.call(c, a[h], h, a) !== {};
          h++
        );
      else
        for (h in a)
          if (a.hasOwnProperty(h) && b.call(c, a[h], h, a) === {}) break
  },
  map: function (a, b, c) {
    var h = []
    if (null == a) return h
    if (this.nativeMap && a.map === this.nativeMap) return a.map(b, c)
    this.each(a, function (k, r, A) {
      h[h.length] = b.call(c, k, r, A)
    })
    return h
  },
  x64Add: function (a, b) {
    a = [a[0] >>> 16, a[0] & 65535, a[1] >>> 16, a[1] & 65535]
    b = [b[0] >>> 16, b[0] & 65535, b[1] >>> 16, b[1] & 65535]
    var c = [0, 0, 0, 0]
    c[3] += a[3] + b[3]
    c[2] += c[3] >>> 16
    c[3] &= 65535
    c[2] += a[2] + b[2]
    c[1] += c[2] >>> 16
    c[2] &= 65535
    c[1] += a[1] + b[1]
    c[0] += c[1] >>> 16
    c[1] &= 65535
    c[0] += a[0] + b[0]
    c[0] &= 65535
    return [(c[0] << 16) | c[1], (c[2] << 16) | c[3]]
  },
  x64Multiply: function (a, b) {
    a = [a[0] >>> 16, a[0] & 65535, a[1] >>> 16, a[1] & 65535]
    b = [b[0] >>> 16, b[0] & 65535, b[1] >>> 16, b[1] & 65535]
    var c = [0, 0, 0, 0]
    c[3] += a[3] * b[3]
    c[2] += c[3] >>> 16
    c[3] &= 65535
    c[2] += a[2] * b[3]
    c[1] += c[2] >>> 16
    c[2] &= 65535
    c[2] += a[3] * b[2]
    c[1] += c[2] >>> 16
    c[2] &= 65535
    c[1] += a[1] * b[3]
    c[0] += c[1] >>> 16
    c[1] &= 65535
    c[1] += a[2] * b[2]
    c[0] += c[1] >>> 16
    c[1] &= 65535
    c[1] += a[3] * b[1]
    c[0] += c[1] >>> 16
    c[1] &= 65535
    c[0] += a[0] * b[3] + a[1] * b[2] + a[2] * b[1] + a[3] * b[0]
    c[0] &= 65535
    return [(c[0] << 16) | c[1], (c[2] << 16) | c[3]]
  },
  x64Rotl: function (a, b) {
    b %= 64
    if (32 === b) return [a[1], a[0]]
    if (32 > b)
      return [
        (a[0] << b) | (a[1] >>> (32 - b)),
        (a[1] << b) | (a[0] >>> (32 - b)),
      ]
    b -= 32
    return [
      (a[1] << b) | (a[0] >>> (32 - b)),
      (a[0] << b) | (a[1] >>> (32 - b)),
    ]
  },
  x64LeftShift: function (a, b) {
    b %= 64
    return 0 === b
      ? a
      : 32 > b
      ? [(a[0] << b) | (a[1] >>> (32 - b)), a[1] << b]
      : [a[1] << (b - 32), 0]
  },
  x64Xor: function (a, b) {
    return [a[0] ^ b[0], a[1] ^ b[1]]
  },
  x64Fmix: function (a) {
    a = this.x64Xor(a, [0, a[0] >>> 1])
    a = this.x64Multiply(a, [4283543511, 3981806797])
    a = this.x64Xor(a, [0, a[0] >>> 1])
    a = this.x64Multiply(a, [3301882366, 444984403])
    return (a = this.x64Xor(a, [0, a[0] >>> 1]))
  },
  x64hash128: function (a, b) {
    a = a || ''
    b = b || 0
    var c = a.length % 16,
      h = a.length - c,
      k = [0, b]
    b = [0, b]
    for (
      var r, A, D = [2277735313, 289559509], F = [1291169091, 658871167], C = 0;
      C < h;
      C += 16
    )
      (r = [
        (a.charCodeAt(C + 4) & 255) |
          ((a.charCodeAt(C + 5) & 255) << 8) |
          ((a.charCodeAt(C + 6) & 255) << 16) |
          ((a.charCodeAt(C + 7) & 255) << 24),
        (a.charCodeAt(C) & 255) |
          ((a.charCodeAt(C + 1) & 255) << 8) |
          ((a.charCodeAt(C + 2) & 255) << 16) |
          ((a.charCodeAt(C + 3) & 255) << 24),
      ]),
        (A = [
          (a.charCodeAt(C + 12) & 255) |
            ((a.charCodeAt(C + 13) & 255) << 8) |
            ((a.charCodeAt(C + 14) & 255) << 16) |
            ((a.charCodeAt(C + 15) & 255) << 24),
          (a.charCodeAt(C + 8) & 255) |
            ((a.charCodeAt(C + 9) & 255) << 8) |
            ((a.charCodeAt(C + 10) & 255) << 16) |
            ((a.charCodeAt(C + 11) & 255) << 24),
        ]),
        (r = this.x64Multiply(r, D)),
        (r = this.x64Rotl(r, 31)),
        (r = this.x64Multiply(r, F)),
        (k = this.x64Xor(k, r)),
        (k = this.x64Rotl(k, 27)),
        (k = this.x64Add(k, b)),
        (k = this.x64Add(this.x64Multiply(k, [0, 5]), [0, 1390208809])),
        (A = this.x64Multiply(A, F)),
        (A = this.x64Rotl(A, 33)),
        (A = this.x64Multiply(A, D)),
        (b = this.x64Xor(b, A)),
        (b = this.x64Rotl(b, 31)),
        (b = this.x64Add(b, k)),
        (b = this.x64Add(this.x64Multiply(b, [0, 5]), [0, 944331445]))
    r = [0, 0]
    A = [0, 0]
    switch (c) {
      case 15:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 14)], 48))
      case 14:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 13)], 40))
      case 13:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 12)], 32))
      case 12:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 11)], 24))
      case 11:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 10)], 16))
      case 10:
        A = this.x64Xor(A, this.x64LeftShift([0, a.charCodeAt(C + 9)], 8))
      case 9:
        ;(A = this.x64Xor(A, [0, a.charCodeAt(C + 8)])),
          (A = this.x64Multiply(A, F)),
          (A = this.x64Rotl(A, 33)),
          (A = this.x64Multiply(A, D)),
          (b = this.x64Xor(b, A))
      case 8:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 7)], 56))
      case 7:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 6)], 48))
      case 6:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 5)], 40))
      case 5:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 4)], 32))
      case 4:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 3)], 24))
      case 3:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 2)], 16))
      case 2:
        r = this.x64Xor(r, this.x64LeftShift([0, a.charCodeAt(C + 1)], 8))
      case 1:
        ;(r = this.x64Xor(r, [0, a.charCodeAt(C)])),
          (r = this.x64Multiply(r, D)),
          (r = this.x64Rotl(r, 31)),
          (r = this.x64Multiply(r, F)),
          (k = this.x64Xor(k, r))
    }
    k = this.x64Xor(k, [0, a.length])
    b = this.x64Xor(b, [0, a.length])
    k = this.x64Add(k, b)
    b = this.x64Add(b, k)
    k = this.x64Fmix(k)
    b = this.x64Fmix(b)
    k = this.x64Add(k, b)
    b = this.x64Add(b, k)
    return (
      ('00000000' + (k[0] >>> 0).toString(16)).slice(-8) +
      ('00000000' + (k[1] >>> 0).toString(16)).slice(-8) +
      ('00000000' + (b[0] >>> 0).toString(16)).slice(-8) +
      ('00000000' + (b[1] >>> 0).toString(16)).slice(-8)
    )
  },
}

var fingerprint = {
  risk_jd_local_fingerprint: xingsha.get(),
  _jdfp_canvas_md5: w(xingsha.getCanvasFp()),
  _jdfp_webgl_md5: w(xingsha.getWebglFp()),
  userAgent: navigator.userAgent,
}

window._jdfp_canvas_md5 = w(xingsha.getCanvasFp())
window._jdfp_webgl_md5 = w(xingsha.getWebglFp())
window._userAgent = navigator.userAgent
window._riskFp = xingsha.get()
