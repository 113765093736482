import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import '@/utils/rem.js'
import './vantui'
import './utils/fingerprint.js'
import './utils/10086fingerprint.js'

Vue.prototype.$axios = axios // 将api挂载到vue的原型上

Vue.config.productionTip = false

Vue.filter('formatPrice', function (price) {
  if (!price) return '0'
  return String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
