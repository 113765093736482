/* eslint-disable */
/**
 * md5方法
 */

var rotateLeft = function (lValue, iShiftBits) {
  return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits))
}
var addUnsigned = function (lX, lY) {
  var lX4, lY4, lX8, lY8, lResult
  lX8 = lX & 0x80000000
  lY8 = lY & 0x80000000
  lX4 = lX & 0x40000000
  lY4 = lY & 0x40000000
  lResult = (lX & 0x3fffffff) + (lY & 0x3fffffff)
  if (lX4 & lY4) return lResult ^ 0x80000000 ^ lX8 ^ lY8
  if (lX4 | lY4) {
    if (lResult & 0x40000000) return lResult ^ 0xc0000000 ^ lX8 ^ lY8
    else return lResult ^ 0x40000000 ^ lX8 ^ lY8
  } else {
    return lResult ^ lX8 ^ lY8
  }
}

var F = function (x, y, z) {
  return (x & y) | (~x & z)
}

var G = function (x, y, z) {
  return (x & z) | (y & ~z)
}

var H = function (x, y, z) {
  return x ^ y ^ z
}

var I = function (x, y, z) {
  return y ^ (x | ~z)
}

var FF = function (a, b, c, d, x, s, ac) {
  a = addUnsigned(a, addUnsigned(addUnsigned(F(b, c, d), x), ac))
  return addUnsigned(rotateLeft(a, s), b)
}

var GG = function (a, b, c, d, x, s, ac) {
  a = addUnsigned(a, addUnsigned(addUnsigned(G(b, c, d), x), ac))
  return addUnsigned(rotateLeft(a, s), b)
}

var HH = function (a, b, c, d, x, s, ac) {
  a = addUnsigned(a, addUnsigned(addUnsigned(H(b, c, d), x), ac))
  return addUnsigned(rotateLeft(a, s), b)
}

var II = function (a, b, c, d, x, s, ac) {
  a = addUnsigned(a, addUnsigned(addUnsigned(I(b, c, d), x), ac))
  return addUnsigned(rotateLeft(a, s), b)
}

var convertToWordArray = function (string) {
  var lWordCount
  var lMessageLength = string.length
  var lNumberOfWordsTempOne = lMessageLength + 8
  var lNumberOfWordsTempTwo =
    (lNumberOfWordsTempOne - (lNumberOfWordsTempOne % 64)) / 64
  var lNumberOfWords = (lNumberOfWordsTempTwo + 1) * 16
  var lWordArray = Array(lNumberOfWords - 1)
  var lBytePosition = 0
  var lByteCount = 0
  while (lByteCount < lMessageLength) {
    lWordCount = (lByteCount - (lByteCount % 4)) / 4
    lBytePosition = (lByteCount % 4) * 8
    lWordArray[lWordCount] =
      lWordArray[lWordCount] | (string.charCodeAt(lByteCount) << lBytePosition)
    lByteCount++
  }
  lWordCount = (lByteCount - (lByteCount % 4)) / 4
  lBytePosition = (lByteCount % 4) * 8
  lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition)
  lWordArray[lNumberOfWords - 2] = lMessageLength << 3
  lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29
  return lWordArray
}

var wordToHex = function (lValue) {
  var WordToHexValue = '',
    WordToHexValueTemp = '',
    lByte,
    lCount
  for (lCount = 0; lCount <= 3; lCount++) {
    lByte = (lValue >>> (lCount * 8)) & 255
    WordToHexValueTemp = '0' + lByte.toString(16)
    WordToHexValue =
      WordToHexValue +
      WordToHexValueTemp.substr(WordToHexValueTemp.length - 2, 2)
  }
  return WordToHexValue
}

var uTF8Encode = function (string) {
  string = string.replace(/\x0d\x0a/g, '\x0a')
  var output = ''
  for (var n = 0; n < string.length; n++) {
    var c = string.charCodeAt(n)
    if (c < 128) {
      output += String.fromCharCode(c)
    } else if (c > 127 && c < 2048) {
      output += String.fromCharCode((c >> 6) | 192)
      output += String.fromCharCode((c & 63) | 128)
    } else {
      output += String.fromCharCode((c >> 12) | 224)
      output += String.fromCharCode(((c >> 6) & 63) | 128)
      output += String.fromCharCode((c & 63) | 128)
    }
  }
  return output
}

var xingsha = {
  md5: function (string) {
    var x = Array()
    var k, AA, BB, CC, DD, a, b, c, d
    var S11 = 7,
      S12 = 12,
      S13 = 17,
      S14 = 22
    var S21 = 5,
      S22 = 9,
      S23 = 14,
      S24 = 20
    var S31 = 4,
      S32 = 11,
      S33 = 16,
      S34 = 23
    var S41 = 6,
      S42 = 10,
      S43 = 15,
      S44 = 21
    string = uTF8Encode(string)
    x = convertToWordArray(string)
    a = 0x67452301
    b = 0xefcdab89
    c = 0x98badcfe
    d = 0x10325476
    for (k = 0; k < x.length; k += 16) {
      AA = a
      BB = b
      CC = c
      DD = d
      a = FF(a, b, c, d, x[k + 0], S11, 0xd76aa478)
      d = FF(d, a, b, c, x[k + 1], S12, 0xe8c7b756)
      c = FF(c, d, a, b, x[k + 2], S13, 0x242070db)
      b = FF(b, c, d, a, x[k + 3], S14, 0xc1bdceee)
      a = FF(a, b, c, d, x[k + 4], S11, 0xf57c0faf)
      d = FF(d, a, b, c, x[k + 5], S12, 0x4787c62a)
      c = FF(c, d, a, b, x[k + 6], S13, 0xa8304613)
      b = FF(b, c, d, a, x[k + 7], S14, 0xfd469501)
      a = FF(a, b, c, d, x[k + 8], S11, 0x698098d8)
      d = FF(d, a, b, c, x[k + 9], S12, 0x8b44f7af)
      c = FF(c, d, a, b, x[k + 10], S13, 0xffff5bb1)
      b = FF(b, c, d, a, x[k + 11], S14, 0x895cd7be)
      a = FF(a, b, c, d, x[k + 12], S11, 0x6b901122)
      d = FF(d, a, b, c, x[k + 13], S12, 0xfd987193)
      c = FF(c, d, a, b, x[k + 14], S13, 0xa679438e)
      b = FF(b, c, d, a, x[k + 15], S14, 0x49b40821)
      a = GG(a, b, c, d, x[k + 1], S21, 0xf61e2562)
      d = GG(d, a, b, c, x[k + 6], S22, 0xc040b340)
      c = GG(c, d, a, b, x[k + 11], S23, 0x265e5a51)
      b = GG(b, c, d, a, x[k + 0], S24, 0xe9b6c7aa)
      a = GG(a, b, c, d, x[k + 5], S21, 0xd62f105d)
      d = GG(d, a, b, c, x[k + 10], S22, 0x2441453)
      c = GG(c, d, a, b, x[k + 15], S23, 0xd8a1e681)
      b = GG(b, c, d, a, x[k + 4], S24, 0xe7d3fbc8)
      a = GG(a, b, c, d, x[k + 9], S21, 0x21e1cde6)
      d = GG(d, a, b, c, x[k + 14], S22, 0xc33707d6)
      c = GG(c, d, a, b, x[k + 3], S23, 0xf4d50d87)
      b = GG(b, c, d, a, x[k + 8], S24, 0x455a14ed)
      a = GG(a, b, c, d, x[k + 13], S21, 0xa9e3e905)
      d = GG(d, a, b, c, x[k + 2], S22, 0xfcefa3f8)
      c = GG(c, d, a, b, x[k + 7], S23, 0x676f02d9)
      b = GG(b, c, d, a, x[k + 12], S24, 0x8d2a4c8a)
      a = HH(a, b, c, d, x[k + 5], S31, 0xfffa3942)
      d = HH(d, a, b, c, x[k + 8], S32, 0x8771f681)
      c = HH(c, d, a, b, x[k + 11], S33, 0x6d9d6122)
      b = HH(b, c, d, a, x[k + 14], S34, 0xfde5380c)
      a = HH(a, b, c, d, x[k + 1], S31, 0xa4beea44)
      d = HH(d, a, b, c, x[k + 4], S32, 0x4bdecfa9)
      c = HH(c, d, a, b, x[k + 7], S33, 0xf6bb4b60)
      b = HH(b, c, d, a, x[k + 10], S34, 0xbebfbc70)
      a = HH(a, b, c, d, x[k + 13], S31, 0x289b7ec6)
      d = HH(d, a, b, c, x[k + 0], S32, 0xeaa127fa)
      c = HH(c, d, a, b, x[k + 3], S33, 0xd4ef3085)
      b = HH(b, c, d, a, x[k + 6], S34, 0x4881d05)
      a = HH(a, b, c, d, x[k + 9], S31, 0xd9d4d039)
      d = HH(d, a, b, c, x[k + 12], S32, 0xe6db99e5)
      c = HH(c, d, a, b, x[k + 15], S33, 0x1fa27cf8)
      b = HH(b, c, d, a, x[k + 2], S34, 0xc4ac5665)
      a = II(a, b, c, d, x[k + 0], S41, 0xf4292244)
      d = II(d, a, b, c, x[k + 7], S42, 0x432aff97)
      c = II(c, d, a, b, x[k + 14], S43, 0xab9423a7)
      b = II(b, c, d, a, x[k + 5], S44, 0xfc93a039)
      a = II(a, b, c, d, x[k + 12], S41, 0x655b59c3)
      d = II(d, a, b, c, x[k + 3], S42, 0x8f0ccc92)
      c = II(c, d, a, b, x[k + 10], S43, 0xffeff47d)
      b = II(b, c, d, a, x[k + 1], S44, 0x85845dd1)
      a = II(a, b, c, d, x[k + 8], S41, 0x6fa87e4f)
      d = II(d, a, b, c, x[k + 15], S42, 0xfe2ce6e0)
      c = II(c, d, a, b, x[k + 6], S43, 0xa3014314)
      b = II(b, c, d, a, x[k + 13], S44, 0x4e0811a1)
      a = II(a, b, c, d, x[k + 4], S41, 0xf7537e82)
      d = II(d, a, b, c, x[k + 11], S42, 0xbd3af235)
      c = II(c, d, a, b, x[k + 2], S43, 0x2ad7d2bb)
      b = II(b, c, d, a, x[k + 9], S44, 0xeb86d391)
      a = addUnsigned(a, AA)
      b = addUnsigned(b, BB)
      c = addUnsigned(c, CC)
      d = addUnsigned(d, DD)
    }
    var tempValue = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d)
    return tempValue.toLowerCase()
  },
}

//canvas生成
function getMyCanvas() {
  var g = [],
    a = document.createElement('canvas'),
    p = a.getContext('2d'),
    S = a
  ;(S.width = 2000),
    (S.height = 200),
    (S.style.display = 'inline'),
    p.rect(0, 0, 10, 10),
    p.rect(2, 2, 6, 6),
    g.push('yes'),
    (p.textBaseline = 'alphabetic'),
    (p.fillStyle = '#f60'),
    p.fillRect(125, 1, 62, 20),
    (p.fillStyle = '#069'),
    (p.font = '11pt no-real-font-123'),
    p.fillText('Cwm fjordbank glyphs vext quiz, 😃', 2, 15),
    (p.fillStyle = 'rgba(102, 204, 0, 0.2)'),
    (p.font = '18pt Arial'),
    p.fillText('Cwm fjordbank glyphs vext quiz, 😃', 4, 45),
    (p.globalCompositeOperation = 'multiply'),
    (p.fillStyle = 'rgb(255,0,255)'),
    p.beginPath(),
    p.arc(50, 50, 50, 0, 2 * Math.PI, true),
    p.closePath(),
    p.fill(),
    (p.fillStyle = 'rgb(0,255,255)'),
    p.beginPath(),
    p.arc(100, 50, 50, 0, 2 * Math.PI, true),
    p.closePath(),
    p.fill(),
    (p.fillStyle = 'rgb(255,255,0)'),
    p.beginPath(),
    p.arc(75, 100, 50, 0, Math.PI * 2, !0),
    p.closePath(),
    p.fill(),
    (p.fillStyle = 'rgb(255,0,255)'),
    p.arc(75, 75, 75, 0, 2 * Math.PI, !0),
    p.arc(75, 75, 25, 0, 2 * Math.PI, !0),
    p.fill('evenodd'),
    S.toDataURL &&
      g.push('ca' + 'n' + 'v' + 'as' + ' ' + 'fp:' + S.toDataURL()),
    g.join('~')
  ret = g.join('~')
  //console.log(ret)
  return ret
}

//WebGl生成
function O() {
  var t = document.createElement('canvas'),
    n = null
  try {
    n = t.getContext('webgl') || t['getContext']('experimental-webgl')
  } catch (r) {}
  return n || (n = null), n
}

function each(e, n) {
  var r = e.length
  if (r === +r) for (var a = 0; a < r && n(e[a], a, e) !== false; a++);
  else
    for (var i in e) if (e['hasOwnProperty'](i) && !1 === n(e[i], i, e)) break
}

function getMyWebGl() {
  var $ = void 0,
    ee = function (o) {
      var u = 'L'
      return (
        $.clearColor(0, 0, 0, 1),
        $.enable($['DEPTH_TEST']),
        $['depthFunc']($['L' + 'E' + 'QUA' + 'L']),
        $.clear($['COLOR_BUFFER_BIT'] | $['DEPTH_BUFFER_BIT']),
        '[' + o[0] + ', ' + o[1] + ']'
      )
    }
  if (!($ = O())) return 'unknown'
  var te = [],
    ne =
      'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}',
    re =
      'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}',
    ae = $['createBuffer']()
  $['bindBuffer']($.ARRAY_BUFFER, ae)
  var ie = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0])
  $['bufferData']($['ARRAY_BUFFER'], ie, $.STATIC_DRAW),
    (ae['itemSize'] = 3),
    (ae.numItems = 3)
  var oe = $['createProgram'](),
    ue = $['createShader']($.VERTEX_SHADER)
  $.shaderSource(ue, ne), $['compileShader'](ue)
  var ce = $['createShader']($.FRAGMENT_SHADER)
  $.shaderSource(ce, re),
    $['compileShader'](ce),
    $['attachShader'](oe, ue),
    $['attachShader'](oe, ce),
    $.linkProgram(oe),
    $.useProgram(oe),
    (oe['vertexPosAttrib'] = $.getAttribLocation(oe, 'attrVertex')),
    (oe['offsetUniform'] = $['getUniformLocation'](oe, 'uniformOffset')),
    $['enableVertexAttribArray'](oe.vertexPosArray),
    $['vertexAttribPointer'](
      oe['vertexPosAttrib'],
      ae['itemSize'],
      $.FLOAT,
      !1,
      0,
      0
    ),
    $.uniform2f(oe.offsetUniform, 1, 1),
    $['drawArrays']($['TRIANGLE_STRIP'], 0, ae.numItems)
  try {
    te.push($.canvas['toDataURL']())
  } catch (z) {}
  te.push('extensions:' + ($.getSupportedExtensions() || []).join(';')),
    te.push(
      'webgl aliased line width' +
        ' range:' +
        ee($.getParameter($['ALIASED_LINE_WIDTH_RANGE']))
    ),
    te.push(
      'webgl aliase' +
        'd point size' +
        ' range:' +
        ee($['getParameter']($.ALIASED_POINT_SIZE_RANGE))
    ),
    te.push('webgl alpha bits:' + $.getParameter($['ALPHA_BITS'])),
    te.push(
      'webgl antialiasing:' +
        ($['getConte' + 'xtAttrib' + 'utes']().antialias ? 'yes' : 'no')
    ),
    te.push('webgl blue bits:' + $.getParameter($['BLUE_BITS'])),
    te.push('webgl depth bits:' + $.getParameter($['DEPT' + 'H_' + 'BITS'])),
    te.push('webgl green bits:' + $['getParameter']($.GREEN_BITS)),
    te.push(
      'webgl max aniso' +
        'tropy:' +
        (function (e) {
          var t =
            e['getExtension']('EXT_texture_filter_anisotropic') ||
            e['getExtension']('WEBKIT_EXT_texture_filter_anisotropic') ||
            e['getExtension']('MOZ_EXT_texture_filter_anisotropic')
          if (t) {
            var i = e.getParameter(t['MAX_TEXTURE_MAX_ANISOTROPY' + '_EXT'])
            return 0 === i && (i = 2), i
          }
          return null
        })($)
    ),
    te.push(
      'webgl max combined texture image units:' +
        $['getParameter']($['MAX_COMBINED_TEXTURE_IMAGE_UNITS'])
    ),
    te.push(
      'webgl max cube map texture size:' +
        $['getParameter']($.MAX_CUBE_MAP_TEXTURE_SIZE)
    ),
    te.push(
      'webgl max fragment uniform vectors:' +
        $['getParameter']($['MAX_FRAGMENT_UNIFORM_VECTORS'])
    ),
    te.push(
      'webgl max render buffer size:' +
        $['getParameter']($['MAX_RENDERBUFFER_SIZE'])
    ),
    te.push(
      'webgl max texture image units:' +
        $.getParameter($.MAX_TEXTURE_IMAGE_UNITS)
    ),
    te.push('webgl max texture size:' + $['getParameter']($.MAX_TEXTURE_SIZE)),
    te.push(
      'webgl max varying vectors:' + $.getParameter($.MAX_VARYING_VECTORS)
    ),
    te.push('webgl max vertex attribs:' + $.getParameter($.MAX_VERTEX_ATTRIBS)),
    te.push(
      'webgl max vertex texture image units:' +
        $['getParameter']($.MAX_VERTEX_TEXTURE_IMAGE_UNITS)
    ),
    te.push(
      'webgl max vertex uniform vectors:' +
        $['getParameter']($['MAX_VERTEX_UNIFORM_VECTORS'])
    ),
    te.push(
      'webgl max viewport dims:' + ee($['getParameter']($['MAX_VIEWPORT_DIMS']))
    ),
    te.push('webgl red bits:' + $['getParameter']($.RED_BITS)),
    te.push('webgl renderer:' + $['getParameter']($['RENDERER'])),
    te.push(
      'webgl shading language version:' +
        $['getParameter']($['SHADING_LANGUAGE_VERSION'])
    ),
    te.push('webgl stencil bits:' + $['getParameter']($['STENCIL_BITS'])),
    te.push('webgl vendor:' + $['getParameter']($['VENDOR'])),
    te.push('webgl version:' + $.getParameter($['VERSION']))
  try {
    var se = $['getExtension']('WEBGL_debug_renderer_info')
    se &&
      (te.push(
        'webgl unmasked vendor:' +
          $['getParameter'](se['UNMASKED_VENDOR_WEBGL'])
      ),
      te.push(
        'webgl unmasked renderer:' +
          $['getParameter'](se.UNMASKED_RENDERER_WEBGL)
      ))
  } catch (z) {}
  return $.getShaderPrecisionFormat
    ? ((0, each)(['FLOAT', 'INT'], function (i) {
        ;(0, each)(['VERTEX', 'FRAGMENT'], function (o) {
          ;(0, each)(['HIGH', 'MEDIUM', 'LO' + 'W'], function (u) {
            var c = 'ion'
            ;(0, each)(['precision', 'rangeMin', 'rangeMax'], function (n) {
              var s = 'precis',
                //, l = $["getShaderPrecisionFormat"]($["_EXT_SHADER"], $["UGvSTX7A\x03A7GA^8_L"])[n];
                l = $['getShaderPrecisionFormat'](
                  $[o + '_SHADER'],
                  $[u + '_' + i]
                )[n]

              n !== s + c && (n = 'precision ' + n)
              var f = [
                'webgl ',
                o.toLowerCase(),
                ' shader ',
                u['toLowerCase'](),
                ' ',
                i['toLowerCase'](),
                ' ',
                n,
                ':',
                l,
              ]
              te['push'](f['join'](''))
            })
          })
        })
      }),
      te['join']('~'))
    : te.join('~')
  ret = te.join('~')
  //console.log(ret)
  return ret
}

let startTime = new Date()
let cd = screen['colorDepth']
let res = [screen.width || 0, screen['height'] || 0].join(';')
let ar = [screen['availWidth'] || 0, screen.availHeight || 0].join(';')
let to = new Date().getTimezoneOffset()
let pr = window.devicePixelRatio
let i = document['documentElement'] || document.body
let vs = [
  window['innerWidth'] || (i ? i['clientWidth'] : 0),
  window['innerHeight'] || (i ? i['clientHeight'] : 0),
].join(';')
let ws = [window.outerWidth, window.outerHeight].join(';')
let ct = new Date() - startTime
let item = {
  cd: cd,
  res: res,
  ar: ar,
  to: to,
  pr: pr,
  vs: vs,
  ws: ws,
  ct: ct,
  canvas: xingsha.md5(getMyCanvas()),
  webgl: xingsha.md5(getMyWebGl()),
}
console.log(item)
window._10086fingerprint = item
