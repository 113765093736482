<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  methods: {},

  mounted() {

  },
}
</script>

<style lang="less">
@import './styles/index.less';

#app {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-family: PingFang SC, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #F8F9FD;
}
</style>
